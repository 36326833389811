import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { BorderClear } from '@mui/icons-material';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        height: '800px',
        backgroundImage: "url('./assets/pexels-pixabay-372470.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#000000'
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 14 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Box sx={{width:256, justifyContent: "center"}}>
            <img width="100%" src={'./assets/logo3.png'} alt={"LOGO"}/>
        </Box>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' }, height: { xs: '400px' } }}>
          <Typography
            textAlign="center"
            color="#eeeeee"
            sx={{ fontSize: '18px', alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Bespoke IoT Solutions
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
